// extracted by mini-css-extract-plugin
export var column = "LocationsDashboardProviderFilter__column__X1eZk";
export var dropdownBody = "LocationsDashboardProviderFilter__dropdownBody__F2ZPu";
export var dropdownBodyRow = "LocationsDashboardProviderFilter__dropdownBodyRow__TLGtz";
export var dropdownTrigger = "LocationsDashboardProviderFilter__dropdownTrigger__SV53t";
export var filterContainer = "LocationsDashboardProviderFilter__filterContainer__H8lMu";
export var label = "LocationsDashboardProviderFilter__label__ZDpAn";
export var providersDropdown = "LocationsDashboardProviderFilter__providersDropdown__jiSWK";
export var providersSearch = "LocationsDashboardProviderFilter__providersSearch__kGP5R";
export var row = "LocationsDashboardProviderFilter__row___IJbD";
export var searchField = "LocationsDashboardProviderFilter__searchField__wPnDs";